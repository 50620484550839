<button [ngClass]="btnClass"
        [class.btn-primary]="hierarchy === 'primary'"
        [class.btn-secondary]="hierarchy === 'secondary'"
        [class.btn-info]="hierarchy === 'tertiary'"
        [class.btn-sm]="size === 'sm'"
        [class.btn-lg]="size === 'lg'"
        [class.w-100]="btnBlock"
        [id]="btnId"
        [disabled]="disabled"
        [type]="type"
        (click)="btnClick.emit($event)"
        *ngIf="!btnLink && !hrefUrl"
        class="btn">
	{{ label }}
</button>
<button [ngClass]="btnClass"
        [class.btn-sm]="size === 'sm'"
        [class.btn-lg]="size === 'lg'"
        [class.w-100]="btnBlock"
        [id]="btnId"
        [disabled]="disabled"
        [type]="type"
        [routerLink]="link"
        (click)="btnClick.emit($event)"
        *ngIf="btnLink && !hrefUrl"
        class="btn btn-link">
	{{ label }}
</button>
<a [ngClass]="btnClass"
   [class.btn-primary]="hierarchy === 'primary'"
   [class.btn-secondary]="hierarchy === 'secondary'"
   [class.btn-info]="hierarchy === 'tertiary'"
   [class.btn-sm]="size === 'sm'"
   [class.btn-lg]="size === 'lg'"
   [class.w-100]="btnBlock"
   [id]="btnId"
   [href]="hrefUrl"
   [target]="target"
   *ngIf="hrefUrl"
   class="btn">
	{{ label }}
</a>