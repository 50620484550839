<button [ngClass]="btnClass"
        [class.btn-outline-primary]="hierarchy === 'primary'"
        [class.btn-outline-secondary]="hierarchy === 'secondary'"
        [class.btn-outline-info]="hierarchy === 'tertiary'"
        [class.btn-sm]="size === 'sm'"
        [class.btn-lg]="size === 'lg'"
        [class.w-100]="btnBlock"
        [id]="btnId"
        [disabled]="disabled || loading"
        [type]="type"
        [routerLink]="link"
        (click)="btnClick.emit($event)"
        *ngIf="!hrefUrl"
        class="btn">
	{{ label }}

	<span *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
		<span class="visually-hidden">Loading...</span>
	</span>
</button>

<a [ngClass]="btnClass"
   [class.btn-outline-primary]="hierarchy === 'primary'"
   [class.btn-outline-secondary]="hierarchy === 'secondary'"
   [class.btn-outline-info]="hierarchy === 'tertiary'"
   [class.btn-sm]="size === 'sm'"
   [class.btn-lg]="size === 'lg'"
   [class.w-100]="btnBlock"
   [id]="btnId"
   [href]="hrefUrl"
   [target]="target"
   *ngIf="hrefUrl"
   class="btn">
	{{ label }}
</a>