<header [ngClass]="{'shadow-none border-bottom-0': isBookingPage}" class="toolbar">
	<app-home-advertisement *ngIf="showAdvertisement"></app-home-advertisement>
	<div class="px-4 px-md-5 py-2 py-md-3" [class.booking]="isBookingPage" [class.page-results]="isResultsPage">
		<div class="toolbar__section">
			<div class="toolbar__section__bars" *ngIf="!isBookingPage" (click)="clickMobileMenu()">
				<app-burger-menu [showMenu]="showsMobileMenu"></app-burger-menu>
			</div>
			<img
				alt="Logo"
				class="toolbar__section__logo"
				[src]="isBookingPage ? './assets/svg/logoRed.svg' : './assets/svg/Logo_nmviajes.svg'"
				(click)="redirectToHome()" />
			<div class="toolbar__section__actions" *ngIf="!isBookingPage" #notifications>
				<a class="btn btn-primary btn-lg btn-custom inter-bold fs-6 d-none d-md-inline-block" href="tel:+51 1 610-9200">
					<span class="inter-medium">Para ventas</span>
					(01) 610-9200
				</a>

				<div class="toolbar__section__actions__icon">
					<img
						src="./assets/svg/home-icon.svg"
						height="25"
						alt="home"
						routerLink="/nuestras-agencias"
						class="pointer" />
					<div class="toolbar__section__actions__icon__arrow"></div>
					<div class="toolbar__section__actions__icon__tooltip support">
						<span>Agencias</span>
					</div>
				</div>
				<div class="toolbar__section__actions__icon">
					<img
						src="./assets/svg/notification.svg"
						alt="Notificaciones"
						class="w-auto"
						height="30"
						[class.notification-animation]="notificationsList.length > 0"
						(click)="showsNotifications = !showsNotifications" />
					<ng-container *ngTemplateOutlet="notificationsBadge"></ng-container>
					<ng-container *ngIf="showsNotifications">
						<div class="toolbar__section__actions__profile__dropdown__arrow notifications"></div>
						<div class="toolbar__section__actions__profile__dropdown__options notifications">
							<div
								class="option flex-nowrap justify-content-between align-items-center gap-2 text-start"
								(click)="redirectFromNotification(notification.url, i)"
								*ngFor="let notification of notificationsList; let i = index">
								<img [src]="notification.iconSrcPath" alt="" class="img-fluid" style="max-width: 25px" />
								<div class="d-flex flex-column flex-nowrap">
									<p class="small mb-1">{{ notification.title }}</p>
									<h5 class="fw-semibold mb-1">{{ notification.description }}</h5>
									<p class="fw-semibold small mb-0">{{ notification.hint }}</p>
								</div>
								<img src="assets/svg/arrowR-red.svg" alt="" class="w-auto" style="height: 0.875rem" />
							</div>
							<div class="option" (click)="showsNotifications = false" *ngIf="notificationsList.length === 0">
								<p class="text-wrap text-center flex-fill my-2">Sin notificaciones</p>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="toolbar__section__actions__icon">
					<img src="./assets/svg/ph_headset.svg" alt="i" routerLink="/canales-de-atencion" />
					<div class="toolbar__section__actions__icon__arrow"></div>
					<div class="toolbar__section__actions__icon__tooltip contact">
						<span>Contáctanos</span>
					</div>
				</div>

				<app-button
					*ngIf="!isLoggedIn"
					[text]="'Iniciar sesión'"
					[isMobile]="true"
					[color]="'outline-primary'"
					(click)="openLoginModal()"></app-button>

				<div class="toolbar__section__actions__profile" *ngIf="isLoggedIn" #dropdown>
					<img
						[src]="userStorage.image"
						class="toolbar__section__actions__profile__image"
						alt=""
						(click)="showsProfileOptions = !showsProfileOptions" />
					<div
						class="toolbar__section__actions__profile__dropdown"
						(click)="showsProfileOptions = !showsProfileOptions">
						<span>{{ userStorage.name.split(' ')[0] }}</span>
						<img src="./assets/svg/dropdown_login.svg" alt="" class="dropdown_img" />
						<ng-container *ngIf="showsProfileOptions">
							<div class="toolbar__section__actions__profile__dropdown__arrow"></div>
							<div class="toolbar__section__actions__profile__dropdown__options">
								<div class="option">
									<img src="./assets/svg/profile.svg" alt="" />
									<span routerLink="/perfil/mi-perfil">Perfil</span>
								</div>
								<div class="option">
									<img src="./assets/svg/logout.svg" alt="" />
									<span class="logout" (click)="logout()">Cerrar sesi&oacute;n</span>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>

			<ng-template #notificationsBadge>
				<span
					class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
					*ngIf="notificationsList.length > 0">
					{{ notificationsList.length }}
					<span class="visually-hidden">Notificaciones sin leer</span>
				</span>
			</ng-template>

			<div
				class="d-flex d-sm-none flex-row flex-nowrap gap-3 justify-content-center toolbar__section__mobile__actions"
				*ngIf="!isBookingPage">
				<div class="position-relative">
					<img
						src="./assets/svg/notification.svg"
						alt="Notificaciones"
						class="profile-mobile bell-icon"
						style="height: 28px; width: auto"
						(click)="openNotificationsMobile()" />
					<ng-container *ngTemplateOutlet="notificationsBadge"></ng-container>
				</div>

				<img
					*ngIf="!isLoggedIn"
					src="./assets/svg/profile-red.svg"
					alt="img"
					class="profile-mobile"
					(click)="openLoginModal()" />

				<img
					*ngIf="isLoggedIn"
					routerLink="/perfil/mi-perfil"
					[src]="userStorage.image"
					alt="img"
					class="profile-mobile" />
			</div>
		</div>
	</div>
	<app-mobile
		[showItems]="showsMobileMenu"
		[isLogged]="isLoggedIn"
		(clickButton)="isLoggedIn ? logout() : openLoginModal()"
		(clickInfo)="clickMobileMenu(true)"></app-mobile>
</header>
