import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ETypeNotification } from '../shared/enums/notification.enum';

export class NotificationModel {
	constructor(
		public show: boolean = false,
		public title: string = 'Error',
		public message: string = '',
		public timeSecond: number = 5,
		public type: ETypeNotification = ETypeNotification.ERROR
	) {}
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
	private readonly notification$ = new BehaviorSubject<NotificationModel>(new NotificationModel());

	constructor() {}

	showNotificacion(
		title = 'Error',
		message: string,
		timeSecond = 5,
		type: ETypeNotification = ETypeNotification.ERROR
	) {
		this.notification$.next(new NotificationModel(true, title, message, timeSecond, type));
	}

	getNotification() {
		return this.notification$.asObservable();
	}
}
