<form [formGroup]="insuranceQuoteForm" novalidate>
	<div class="row gx-3 gy-md-3 gy-sm-3 gy-xs-2 gy-2">
		<div class="col-md-12">
			<article>
				<div class="tag__passenger" #tagSecure>
					<div class="tag__passenger__name" (click)="showPassengerBox = !showPassengerBox">
						<img src="./assets/svg/people-red2.svg" alt="" />
						<span>{{ limit }} pasajero{{ limit > 1 ? 's' : '' }}</span>
					</div>
					<!-- PASAJEROS -->
					<div class="boxFlotante seguro" [hidden]="!showPassengerBox">
						<div formArrayName="passengers">
							<div
								[formGroupName]="i"
								*ngFor="let pass of passengers.controls; let i = index"
								class="boxTwoPasajero d-flex flex-row align-items-center p-2">
								<div class="d-flex flex-row flex-column w-100">
									<div class="d-flex flex-row text1">
										<img src="./assets/filter/icon-user.png" alt="icon-user" /> Pasajero {{ i + 1 }}
									</div>
								</div>
								<div class="d-flex flex-row number">
									<div class="d-flex flex-row textBtn">
										<input
											formControlName="edad"
											class="inputEdadPasajero"
											type="text"
											placeholder="Edad"
											maxlength="2"
											(keypress)="allowNumeric($event)"
											(paste)="onPasteNumeric($event)"
											(drop)="denyDrop($event)"
											[ngClass]="getMessage(MSG_CUSTOMERS) != MSG_EMPTY ? 'isvalid' : ''" />
									</div>

									<div class="d-flex flex-row jus justify-content-end align-items-center opcionBtn">
										<img *ngIf="limit > 1" (click)="deletePassenger(i)" src="./assets/filter/delete.png" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div class="d-flex flex-row justify-content-between align-items-center p-2 btnPasajero">
							<button class="Agregar" [disabled]="limitePassenger" type="button" (click)="addPassenger()">
								+ Agregar pasajero
							</button>
							<button type="button" (click)="confirmPassengers()">Listo</button>
						</div>
					</div>
				</div>
				<div class="tag__info">
					<img src="./assets/svg/arrow-left-info.svg" alt="<-" />
					<span>Ingresa aquí la información de los pasajeros</span>
				</div>
			</article>
		</div>
		<div class="col-md-12">
			<div class="inputs__form">
				<section>
					<div class="columnFiltro flexCero width110 boxHideM">
						<div class="iconBandera">
							<img src="./assets/svg/flag-peru.svg" alt="" />
						</div>
						<select class="form-select selectSeguro" formControlName="origenSafe" aria-label="Default select example">
							<option selected value="510">PE</option>
						</select>
					</div>

					<select
						#destino
						[ngClass]="getMessage(MSG_DESTINO) != MSG_EMPTY ? 'isvalid' : ''"
						class="form-select selectSeguro"
						formControlName="destinoSafe"
						aria-label="Default select example"
						required>
						<option disabled selected>Destino</option>
						<option *ngFor="let o of options" value="{{ o.ref_assistcard }}">{{ o.descripcion_destino }}</option>
					</select>
				</section>
				<app-input-range
					#childDates
					[fromDate]="insuranceQuoteForm.get('fromDate')?.value"
					[toDate]="insuranceQuoteForm.get('toDate')?.value"
					[typeFlight]="-1"></app-input-range>
				<app-button [isMobile]="true" [fullWidth]="true" [text]="'Cotizar ahora'" (onClick)="quoteNow()"></app-button>
			</div>
		</div>
	</div>
</form>
