<section class="container__chooseus">
    <div class="container__chooseus__section">
        <header>
            <h2 class="fs-2 fw-bolder text-center">Por qué elegirnos</h2>
            <p class="text-center">Confía en nuestra experiencia y dedicación para brindarte experiencias de viaje inolvidables.</p>
        </header>
        <section class="container__chooseus__section__options">
            <ng-container *ngFor="let item of dataSection">
                <article class="container__chooseus__section__options__option">
                    <picture>
                        <img [src]="item.img" alt="{{item.title}}">
                    </picture>
                    <div class="container__chooseus__section__options__option__info">
                        <h3>{{item.title}}</h3>
                        <p>{{item.description}}</p>
                    </div>
                </article>
            </ng-container>
        </section>
    </div>
</section>