import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from './components';
import { HomeModule } from '../features/home';
import { OffersModule } from '../Component/home-page/offers/offers.module';
import { ResponsabilidadSocialModule } from '../Component/home-page/resonsabilidad-social/responsabilidad-social.module';
import { CondicionesDeReservaModule } from '../Component/home-page/condiciones-de-reserva/condiciones-de-reserva.module';
import { PoliticasModule } from '../Component/home-page/politicas/politicas.module';
import { NuestraEmpresaModule } from '../Component/home-page/nuestra-empresa/nuestra-empresa.module';
import { NuestrasAgenciasModule } from '../Component/home-page/nuestras-agencias/nuestras-agencias.module';
import { RetailModule } from '../Component/home-page/retail/retail.module';
import { LibroReclamacionesModule } from '../Component/home-page/libro-reclamaciones/libro-reclamaciones.module';
import { CierrapuertasOfertasModule } from '../Component/home-page/cierrapuertas-ofertas/cierrapuertas-ofertas.module';
import { CierrapuertasModule } from '../Component/home-page/cierrapuertas/cierrapuertas.module';
import { TusDatosModule } from '../Component/home-page/tus-datos/tus-datos.module';
import { DestinosModule } from '../Component/home-page/vuelos/commons/components/destinos/destinos.module';
import { CitaModule } from '../Component/home-page/cita/cita.module';
import { ResultadosModule } from '../Component/home-page/resultados/resultados.module';
import { ConformidadModule } from '../Component/home-page/conformidad/conformidad.module';
import { AerolineasModule } from '../Component/home-page/aerolineas/aerolineas.module';
import { TerminosCondicionesModule } from '../Component/home-page/terminos-condiciones/terminos-condiciones.module';
import { DocumentacionViajeModule } from '../Component/home-page/documentacion-viaje/documentacion-viaje.module';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		ToolbarModule,
		HomeModule,
		CitaModule,
		HomeModule, 
		ResultadosModule,
		ConformidadModule,
		TerminosCondicionesModule,
		DocumentacionViajeModule,
		DestinosModule,
		TusDatosModule,
		AerolineasModule,  
		DestinosModule, 
		CierrapuertasModule,
		CierrapuertasOfertasModule,
		LibroReclamacionesModule,
		RetailModule,
		NuestrasAgenciasModule,
		NuestraEmpresaModule,
		PoliticasModule,
		CondicionesDeReservaModule,
		ResponsabilidadSocialModule,
		OffersModule
	],
	exports: [
		ToolbarModule
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule)
			throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
	}
}
