<div class="faq">
	<div *ngFor="let item of faqItems" class="faq-item">
		<div
			class="d-flex justify-content-between faq-title"
			[ngStyle]="{ 'border-bottom': item.status ? '1px solid #e0e0e0' : '' }"
			(click)="item.status = !item.status">
			<h3>
				{{ item.pregunta }}
			</h3>
			<img
				src="assets/vuelos/destino/faq-arrow.svg"
				[ngStyle]="{ transform: item.status ? '' : 'rotate(180deg)' }"
				alt="" />
		</div>
		<div *ngIf="item.status" [@openClose]>
			<p class="faq-answer ql-editor" [innerHTML]="item.respuesta"></p>
		</div>
	</div>
</div>
