import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CheckoutService } from '../../api/api-checkout/services/checkout.service';
import { NMRequest } from '../../Models/base/NMRequest';
import { BusinessUnitService } from '../../Services/businessUnit/business-unit.service';
import { DataPagePresenterService } from '../../Services/presenter/data-page-presenter.service';
import { MainService } from '../../Services/presenter/main/main.service';
import { ToolbarService } from '../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: [ './container.component.scss' ]
})
export class ContainerComponent implements OnInit, OnDestroy {
	private _ipAddress: string = 'xyx';

	statusShowToolbar: boolean = false;
	unsubscribe$ = new Subject<any>();
	advertisementContent: string = '';

	options: string[];
	isBookingRoute: boolean;

	constructor(
			@Inject(PLATFORM_ID) private _platformId: Object,
			private _businessUnitService: BusinessUnitService,
			private _mainService: MainService,
			private _checkoutService: CheckoutService,
			private _toolbarService: ToolbarService,
			private _router: Router,
			public dataPagePresenterService: DataPagePresenterService
	) {
		this.isBookingRoute = this._router.url.includes('booking');
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.getBusinessUnit();
			this.setIpCliente();
			this.getOptions();
			this._checkoutService.resetValuesForms();

			localStorage.removeItem('filters');
		}
		this._toolbarService.statusShowAdvertisement$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
			this.statusShowToolbar = status;
		});

		this._toolbarService.advertisementContent$.pipe(takeUntil(this.unsubscribe$)).subscribe((content) => {
			this.advertisementContent = content;
		});
	}

	private getBusinessUnit() {
		if (localStorage.getItem('businessunit') === null) {
			let payload = new NMRequest();

			this._businessUnitService.businessUnit(payload).subscribe(
					(data) => {
						let linfo = data['Resultado'].filter((und: any) => und.id_unidad_negocio == environment.undidadNegocioAC);
						localStorage.setItem('businessunit', linfo.length > 0 ? JSON.stringify(linfo[0]) : '');
					},
					(err) => console.log(err)
			);
		}
	}

	private getOptions() {
		this._mainService.getMenu().subscribe({
			next: (response: any) => this.options = response,
			error: (err) => console.error(err)
		});
	}

	private setIpCliente() {
		localStorage.setItem('ipCliente', this._ipAddress);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
