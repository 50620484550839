<div class="container__range" #calendar>
	<div
		(click)="openCalendar()"
		(focusin)="onFocus(0)"
		[class.multi]="typeFlight === 2"
		[class.active]="showCalendar && focused[0]"
		class="container__range__date"
		tabindex="0">
		<img src="./assets/svg/calendar.svg" alt="" />
		<span [class.inactive]="dateDeparture === ''">{{ dateDeparture === '' ? 'Salida' : dateDeparture }}</span>
	</div>
	<div
		*ngIf="typeFlight !== 2"
		tabindex="0"
		(click)="openCalendar()"
		class="container__range__date"
		(focus)="typeFlight < 1 ? onFocus(1) : null"
		(focusin)="typeFlight < 1 ? openCalendar() : null"
		[class.inactive]="typeFlight >= 1"
		[class.active]="showCalendar && focused[1] && typeFlight <= 0">
		<img [src]="typeFlight >= 1 ? './assets/svg/calendar-inactive.svg' : './assets/svg/calendar.svg'" alt="" />
		<span *ngIf="typeFlight <= 0" [class.inactive]="dateReturn === ''">
			{{ dateReturn === '' ? 'Retorno' : dateReturn }}
		</span>
		<span *ngIf="typeFlight === 1" class="inactive">Retorno</span>
	</div>
	<section *ngIf="showCalendar && typeFlight < 1">
		<ng-container *ngTemplateOutlet="datepicker1"></ng-container>
	</section>

	<section *ngIf="typeFlight > 0" class="only">
		<ng-container *ngTemplateOutlet="datepicker2"></ng-container>
	</section>
</div>

<ng-template #datepicker1>
	<div class="container__range__arrow"></div>
	<div class="container__range__datepicker">
		<div class="container__range__datepicker__calendars">
			<ngb-datepicker
				(dateSelect)="onDateSelection($event)"
				[dayTemplate]="dayTemplate"
				[displayMonths]="2"
				[minDate]="minDate"
				[startDate]="startDate ?? minDate"
				outsideDays="collapsed"></ngb-datepicker>
		</div>
		<div class="container__range__datepicker__buttons d-md-none">
			<app-button
				[text]="'Cancelar'"
				[size]="'xs'"
				[isMobile]="true"
				[color]="'neutral'"
				(onClick)="openCalendar(true)"></app-button>
		</div>
	</div>
</ng-template>

<ng-template #datepicker2>
	<div class="container__range__arrow" *ngIf="showCalendar"></div>
	<div class="container__range__datepicker" *ngIf="showCalendar">
		<div class="container__range__datepicker__calendar">
			<div class="container__range__datepicker__calendar__actions">
				<div class="ngb-dp-arrow">
					<button
						type="button"
						class="btn btn-link ngb-dp-arrow-btn"
						(click)="changeMonth(-1)"
						[disabled]="disabledPrevMonth">
						<span class="ngb-dp-navigation-chevron"></span>
					</button>
				</div>
				<span>{{ dateOneWay.month | monthToText }} {{ dateOneWay.year }}</span>
				<div class="ngb-dp-arrow right">
					<button type="button" class="btn btn-link ngb-dp-arrow-btn" (click)="changeMonth(1)">
						<span class="ngb-dp-navigation-chevron"></span>
					</button>
				</div>
			</div>
			<ngb-datepicker
				#dp2
				[(ngModel)]="selectedDateOneWay"
				(navigate)="dateOneWay = $event.next"
				(dateSelect)="applyDate()"
				[dayTemplate]="customDay"
				[minDate]="minDate"
				[outsideDays]="'collapsed'">
			</ngb-datepicker>
		</div>
		<div class="container__range__datepicker__buttons d-md-none">
			<app-button (onClick)="openCalendar(true)" [color]="'neutral'" [text]="'Cancelar'" [size]="'xs'"></app-button>
		</div>
	</div>
</ng-template>

<ng-template #dayTemplate let-date let-focused="focused">
	<span
		class="custom-day"
		[class.focused]="focused"
		[class.range]="isRange(date)"
		[class.faded]="isHovered(date) || isInside(date)"
		(mouseenter)="hoveredDate = date"
		(mouseleave)="hoveredDate = null">
		{{ date.day }}
	</span>
</ng-template>

<ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
	<span
		[class.range]="
			selected ||
			(selectedDateOneWay
				? selectedDateOneWay.year === date.year &&
					selectedDateOneWay.day === date.day &&
					selectedDateOneWay.month === date.month
				: false)
		"
		class="custom-day"
		*ngIf="showCalendar">
		{{ date.day }}
	</span>
</ng-template>
