<div class="card border-0 shadow h-100">
	<div [ngStyle]="{'height.rem': imgTopStyle === 'sm' ? '4' : imgTopStyle === 'md' ? '6' : '8'}"
	     *ngIf="imgTopStyle !== 'none'"
	     class="card-img-top placeholder-glow w-100 overflow-hidden">
		<span class="placeholder col-12 h-100 rounded-bottom-0"></span>
	</div>
	<div class="card-body">
		<p class="card-text placeholder-glow mb-1">
			<span class="placeholder col-5"></span>
		</p>
		<h5 class="card-title placeholder-glow mb-0">
			<span class="placeholder col-10"></span>
		</h5>
		<p class="card-text placeholder-glow mb-4">
			<span class="placeholder col-6"></span>
		</p>
		<p class="card-text text-end placeholder-glow mb-0">
			<span class="placeholder col-6"></span>
		</p>
		<p class="display-6 text-end placeholder-glow">
			<span class="placeholder col-10"></span>
		</p>
		<app-filled-button [disabled]="true"
		                   [btnBlock]="true"
		                   [ngClass]="'col-12 btn-placeholder placeholder bg-transparent'"
		                   btnClass="placeholder"
		                   hierarchy="primary"></app-filled-button>
	</div>
</div>