<section class="travel-tab-container search__container p-3 p-md-4 px-lg-5">
	<header class="container search__container__title">
		<h1 class="fs-2 text-white">Vuelos baratos a {{ destino | titlecase }}</h1>
	</header>
	<div class="container search__container__metasearch__form">
		<app-tab-vuelos-v2></app-tab-vuelos-v2>
	</div>
</section>

<section class="boxDestino">
	<div class="container mt-5">
		<aside class="text-center">
			<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_111:2572)">
					<path
						d="M6.91414 32.9741C6.41483 33.2084 6.19453 33.7982 6.41705 34.3022L8.69775 39.1982C8.8098 39.442 9.01422 39.6318 9.26562 39.7254C9.37576 39.7654 9.49258 39.7861 9.61002 39.7861C9.75699 39.7873 9.90206 39.7559 10.0357 39.6953L27.2674 31.6466L25.7066 47.9658C25.6765 48.287 25.8003 48.6028 26.0406 48.8174C26.796 49.4428 27.7375 49.7989 28.7171 49.831C28.9872 49.8306 29.2558 49.7967 29.5176 49.73C30.9165 49.365 32.0012 48.1185 32.7411 45.9895L39.5222 25.9098L52.9525 19.6451C56.9057 17.8006 58.9429 14.0604 57.5748 11.141C56.7984 9.61514 55.3113 8.57716 53.6114 8.3737C51.7228 8.08865 49.7925 8.38576 48.0771 9.22566L32.5786 16.4931L15.915 9.97542C13.8165 9.18503 12.1545 9.20535 10.9889 10.0468C10.0109 10.8493 9.45449 12.0555 9.47829 13.3204C9.48845 13.6414 9.65002 13.9382 9.91412 14.1216L21.1348 21.835L14.7088 24.876L3.93352 22.1388C3.49198 22.0303 3.03235 22.2293 2.80857 22.6254L0.122834 27.4601C-0.0165151 27.7163 -0.0384174 28.0204 0.0628411 28.294C0.163465 28.5679 0.377409 28.7847 0.650077 28.8895L8.62728 32.184L6.91414 32.9741ZM11.5558 12.8138C11.6155 12.3853 11.8323 11.9952 12.1643 11.719C12.7424 11.3032 13.7965 11.3642 15.2053 11.8911L30.0449 17.679L23.2736 20.8415L11.5558 12.8138ZM2.43369 27.4503L4.1973 24.3183L14.5666 26.8926C14.7901 26.9491 15.0259 26.9278 15.2361 26.8323L48.9389 11.0905C50.2788 10.4297 51.7878 10.189 53.267 10.4011C54.3044 10.4966 55.2284 11.0953 55.7404 12.0025C56.5911 13.8274 54.9189 16.4931 52.0907 17.8107L40.4139 23.2847L41.1236 21.1859C41.303 20.646 41.01 20.0625 40.4701 19.8835C39.9295 19.7045 39.3464 19.9975 39.1673 20.5374L30.8251 45.3607C30.3283 46.77 29.71 47.6115 29.0209 47.7934C28.5939 47.8756 28.1521 47.7918 27.7842 47.5607L29.8116 26.2742C29.8646 25.7146 29.4541 25.2178 28.8939 25.1648C28.3343 25.1112 27.8375 25.5223 27.7842 26.0819L27.4702 29.3355L10.076 37.4444L8.64759 34.4035L11.5054 33.0659C11.8733 32.9037 12.1104 32.5399 12.1104 32.1383C12.1104 31.7365 11.8733 31.3727 11.5054 31.2108L2.43369 27.4503Z"
						fill="#4B4B4B" />
				</g>
				<defs>
					<clipPath id="clip0_111:2572">
						<rect width="58" height="58" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</aside>
		<header>
			<h2 class="rowDestino titulo travel__title">{{ title }}</h2>
			<p class="rowDestino subtitulo mt-3 travel__description">{{ subTitle }}</p>
		</header>
		

		<div class="verDesktop w-100">
			<div class="rowDestino tableDestino bold"></div>

			<div class="rowDestino tableDestino" *ngFor="let item of vuelos?.slice(0, limit)">
				<div class="colDestino">
					<img src="assets/vuelos/destino/jet_icon.svg" alt="" />
					<h5 class="mb-0">SALIDA</h5>
					<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
							stroke="#DF0C30"
							stroke-linecap="round"
							stroke-linejoin="round" />
						<path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" /></svg
					>{{ item.DateStart }}
				</div>
				<div class="colDestino">
					<img src="assets/vuelos/destino/return_icon.svg" alt="" />
					<h5 class="mb-0">RETORNO</h5>
					<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
							stroke="#DF0C30"
							stroke-linecap="round"
							stroke-linejoin="round" />
						<path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" /></svg
					>{{ item.DateEnd }}
				</div>
				<div class="colDestino boldTarifa">{{ '$ ' + item.Total }}</div>
				<div class="colDestino">
					<app-filled-button
						[hierarchy]="'primary'"
						[size]="'md'"
						[label]="'Comprar'"
						(btnClick)="buscarVuelo(item)"></app-filled-button>
				</div>
			</div>
		</div>

		<div class="contentTabMobile">
			<div class="boxInter">
				<div class="rowAero tableDestino" *ngFor="let item of vuelos?.slice(0, limit)">
					<div class="rowTab">
						<div class="d-flex gap-2">
							<img src="assets/vuelos/destino/jet_icon.svg" alt="" />
							<h5 class="mb-0">SALIDA</h5>
						</div>
						<div class="d-flex gap-2">
							<img src="assets/vuelos/destino/return_icon.svg" alt="" />
							<h5 class="mb-0">RETORNO</h5>
						</div>
					</div>

					<div class="rowTab">
						<div class="cLeft">
							<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
									stroke="#DF0C30"
									stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" /></svg
							>{{ item.DateStart }}
						</div>
						<div class="cRight">
							<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M16.2727 2.90906H4.81818C2.70946 2.90906 1 4.61852 1 6.72724V16.2727C1 18.3814 2.70946 20.0909 4.81818 20.0909H16.2727C18.3815 20.0909 20.0909 18.3814 20.0909 16.2727V6.72724C20.0909 4.61852 18.3815 2.90906 16.2727 2.90906Z"
									stroke="#DF0C30"
									stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M6.72723 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M14.3637 1V4.81818" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M1 8.63635H20.0909" stroke="#DF0C30" stroke-linecap="round" stroke-linejoin="round" /></svg
							>{{ item.DateEnd }}
						</div>
					</div>
					<div class="rowTab">
						<div class="cLeft boldTarifa">{{ '$ ' + item.Total }}</div>
						<div class="cRight">
							<app-filled-button
								[hierarchy]="'primary'"
								[size]="'md'"
								[label]="'Comprar'"
								(btnClick)="buscarVuelo(item)"></app-filled-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center pt-3" *ngIf="vuelos?.length >= 2">
			<app-outline-button
				[label]="vuelos.length <= limit ? 'Ver menos' : 'Ver más ofertas'"
				[btnOutline]="true"
				size="sm"
				[disabled]="isLoading"
				(btnClick)="viewMoreOffers(vuelos.length === limit)"></app-outline-button>
		</div>
		<p class="footerTable text">  
				Tarifas publicadas sujetos a cambio. Precios basados en disponibilidad. Algunas restricciones y fechas de
				bloqueo son aplicables. <strong>Tarifa no incluye Gastos Administrativos.</strong>  
		</p>

		<app-destination *ngIf="destinationContent" [destinationContent]="destinationContent"></app-destination>

		<div class="d-flex flex-wrap card-detail-container my-4">
			<ng-container *ngFor="let item of informationCard">
				<app-card-detail [image]="item.imagenUrl" [flightContent]="item.detalle"></app-card-detail>
			</ng-container>
		</div>
	</div>
</section>

<section class="mt-3 py-4 bg-white">
	<section class="container">
		<section class="mb-5">
			<app-email-subscription [city]="destino"></app-email-subscription>
		</section>
		<section class="mb-4" *ngIf="seoFaq?.length">
			<h4 class="px-5 fw-bold">Preguntas Frecuentes</h4>
			<app-faq [faqItems]="seoFaq"></app-faq>
		</section>
		<div class="px-5 popular-routes">
			<ng-container *ngFor="let item of popularRoutesSection">
				<app-routes-list [popularRoutes]="item" [destination]="destino"></app-routes-list>
			</ng-container>
		</div>
	</section>
</section>
