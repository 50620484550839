<form class="bg-white" [formGroup]="form">
    <div class="container bg-white">
        <!-- COMBOS -->
        <div class="row filter">
            <div class="col-12">
                <div class="w-130">
                    <mat-form-field>
                        <!-- <mat-select formControlName="tipo" [(ngModel)]="selected" disableOptionCentering> -->
                        <mat-select formControlName="tipo" disableOptionCentering>
                            <mat-option value="idavuelta">Ida y vuelta</mat-option>
                            <mat-option value="ida">Solo ida</mat-option>
                            <mat-option value="multi">Multi city</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-130">
                    <mat-form-field>
                        <mat-select formControlName="catergory" disableOptionCentering>
                            <mat-option value="economy">Economy</mat-option>
                            <mat-option value="premium">Premium</mat-option>
                            <mat-option value="business">Business</mat-option>
                            <mat-option value="firstclass">First class</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-130">
                    <!-- <button class="customers" (click)="customers($event)">10 Pasajero</button> -->
                    <div class="boxFlotante" *ngIf="!showOption">
                        <div class="boxTwoPasajero d-flex flex-row align-items-center p-3">
                            <div class="d-flex flex-row iconTwo">
                                <img height="13px" src="./assets/filter/adulto.png" alt="">
                            </div>
                            <div class="d-flex flex-row flex-column w-100">
                                <div class="d-flex flex-row text1">
                                    Adultos
                                </div>
                                <div class="d-flex flex-row text2">
                                    12 o más años
                                </div>
                            </div>
                            <div class="d-flex flex-row number">
                                <div class="d-flex flex-row btnNWhite">
                                    -
                                </div>
                                <div class="d-flex flex-row textBtn">
                                    <input class="inputPasajero" type="text" value="0">
                                </div>
                                <div class="d-flex flex-row btnNWhite">
                                    +
                                </div>
                            </div>
                        </div>
                        <div class="boxTwoPasajero d-flex flex-row align-items-center p-3">
                            <div class="d-flex flex-row iconTwo">
                                <img height="13px" src="./assets/filter/nino.png" alt="">
                            </div>
                            <div class="d-flex flex-row flex-column w-100">
                                <div class="d-flex flex-row text1">
                                    Niños
                                </div>
                                <div class="d-flex flex-row text2">
                                    De 2 - 11 años
                                </div>
                            </div>
                            <div class="d-flex flex-row number">
                                <div class="d-flex flex-row btnNWhite">
                                    -
                                </div>
                                <div class="d-flex flex-row textBtn">
                                    <input class="inputPasajero" type="text" value="0">
                                </div>
                                <div class="d-flex flex-row btnNWhite">
                                    +
                                </div>
                            </div>
                        </div>
                        <div class="boxTwoPasajero d-flex flex-row align-items-center p-3">
                            <div class="d-flex flex-row iconTwo">
                                <img height="13px" src="./assets/filter/infante.png" alt="">
                            </div>
                            <div class="d-flex flex-row flex-column w-100">
                                <div class="d-flex flex-row text1">
                                    Infantes
                                </div>
                                <div class="d-flex flex-row text2">
                                    Menores de 2 años
                                </div>
                            </div>
                            <div class="d-flex flex-row number">
                                <div class="d-flex flex-row btnNWhite">
                                    -
                                </div>
                                <div class="d-flex flex-row textBtn">
                                    <input class="inputPasajero" type="text" value="0">
                                </div>
                                <div class="d-flex flex-row btnNWhite">
                                    +
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row justify-content-between align-items-center p-3 btnPasajero">
                            <button class="gray" type="button" (click)="showPasajero()">Cancelar</button>
                            <button type="button" (click)="showPasajero()">Listo</button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <button class="customers" (click)="showPasajero()">10 Pasajero</button>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- END COMBOS -->

        <!-- FILTROS -->
        <div class="portletFiltro">
            <div class="rowFiltro">
                <!-- ORIGEN -->
                <div class="columnFiltro narginColumn">
                    <div class="iconCalendar" style="display: none;">
                        <svg class="" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.5858 13.8566H0.414201C0.185479 13.8566 0 14.042 0 14.2708C0 14.4996 0.185479 14.685 0.414201 14.685H20.5858C20.8145 14.685 21 14.4996 21 14.2708C21 14.042 20.8145 13.8566 20.5858 13.8566Z"
                                fill="#009AED" />
                            <path d="M0.199796 7.43146L3.25258 9.518C3.94007 9.98791 4.74826 10.2276 5.55989 10.2276C6.14744 10.2276 6.7368 10.1019 7.28313 9.84695L10.2909 8.44124C10.4981 8.34436 10.5875 8.09782 10.4907 7.89064C10.3939 7.68341 10.1474 7.59399 9.94007 7.69075L6.9326 9.09642C5.89556 9.58029 4.66464 9.47981 3.71997 8.83411L1.19504 7.10834L2.33144 6.41132L5.25599 7.38138C5.35685 7.41489 5.46674 7.40826 5.56291 7.36295L9.04759 5.72147C9.17744 5.66029 9.26633 5.53632 9.28261 5.39371C9.29888 5.25114 9.24019 5.11027 9.12745 5.02143L5.28233 1.99142L6.77777 1.22838L13.6943 3.4166C13.7936 3.44804 13.9011 3.44083 13.9954 3.3966L18.6229 1.22216C19.1452 1.00355 19.7774 1.24242 20.0349 1.75694C20.1666 2.02004 20.1858 2.31793 20.0891 2.59578C19.9924 2.87363 19.7923 3.09514 19.5256 3.21965L13.3091 6.12374C13.1018 6.22054 13.0123 6.46707 13.1091 6.6743C13.2059 6.88152 13.4524 6.97115 13.6597 6.87427L19.8761 3.97026C20.347 3.75045 20.7005 3.35907 20.8714 2.8682C21.0424 2.37733 21.0084 1.851 20.7757 1.38623C20.3248 0.485339 19.2104 0.0711376 18.2917 0.462889C18.2871 0.464836 18.2825 0.466907 18.278 0.46902L13.7898 2.57801L6.86574 0.387339C6.76207 0.354534 6.64949 0.363812 6.55252 0.413309L4.32971 1.54752C4.20396 1.61168 4.11955 1.73552 4.10575 1.87602C4.09196 2.01652 4.1507 2.15441 4.26162 2.2418L8.08863 5.25752L5.35971 6.54299L2.40948 5.5644C2.29354 5.526 2.16663 5.54063 2.0625 5.60445L0.216944 6.73643C0.09724 6.80982 0.0228909 6.93893 0.0194116 7.07926C0.0159738 7.21967 0.0839028 7.35222 0.199796 7.43146Z"
                                fill="#009AED" />
                        </svg>
                    </div>

                    <input matInput aria-label="State" [ngClass]="classAutoOrigen ? 'auto' : ''" [matAutocomplete]="auto" (keyup)="autoComplete($event)" (focus)="autoComplete($event)" (blur)="autoComplete($event)" #inputOrigen class="form-control custom animated fadeIn origen"
                        formControlName="origen" name="origen" placeholder="Origen">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let state of citys" [value]="state.CITY">
                            <img class="example-option-img" aria-hidden src="./assets/origen.png">
                            <span>{{state.CITY}}</span>
                            <!-- <small>Population: {{state.population}}</small> -->
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <!-- END ORIGEN -->

                <!-- DESTINO -->
                <div class="columnFiltro narginColumn">
                    <div class="iconCalendar" style="display: none;">
                        <svg class="" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3088 11.2321C12.1178 8.39349 11.8904 8.74763 11.9425 8.67362C12.6011 7.74466 12.9492 6.65132 12.9492 5.51172C12.9492 2.48957 10.4968 0 7.4707 0C4.45446 0 1.99219 2.48466 1.99219 5.51172C1.99219 6.65059 2.34759 7.77255 3.02779 8.71403L4.63257 11.2322C2.9168 11.4958 0 12.2816 0 14.0117C0 14.6424 0.411652 15.5412 2.37276 16.2416C3.74213 16.7307 5.55259 17 7.4707 17C11.0575 17 14.9414 15.9882 14.9414 14.0117C14.9414 12.2813 12.028 11.4963 10.3088 11.2321ZM3.85983 8.16614C3.85435 8.15758 3.84864 8.14921 3.84266 8.14097C3.27665 7.36229 2.98828 6.43945 2.98828 5.51172C2.98828 3.02089 4.99395 0.996094 7.4707 0.996094C9.94231 0.996094 11.9531 3.02178 11.9531 5.51172C11.9531 6.44094 11.6702 7.33248 11.1348 8.09064C11.0868 8.15392 11.3371 7.76505 7.4707 13.8321L3.85983 8.16614ZM7.4707 16.0039C3.55293 16.0039 0.996094 14.8523 0.996094 14.0117C0.996094 13.4467 2.30988 12.5177 5.22113 12.1556L7.05068 15.0265C7.14213 15.17 7.3005 15.2568 7.47067 15.2568C7.64084 15.2568 7.79925 15.1699 7.89066 15.0265L9.72018 12.1556C12.6315 12.5177 13.9453 13.4467 13.9453 14.0117C13.9453 14.8452 11.4115 16.0039 7.4707 16.0039Z"
                                fill="#009AED" />
                        </svg>
                    </div>

                    <input matInput aria-label="State2" [ngClass]="classAutoDestino ? 'auto' : ''" (keyup)="autoComplete($event)" (focus)="autoComplete($event)" (blur)="autoComplete($event)" name="destino" #inputDestino class="form-control custom destino" formControlName="destino"
                        [matAutocomplete]="auto" [formControl]="stateCtrl2" placeholder="Destino">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let state of citys" [value]="state.CITY">
                            <img class="example-option-img" aria-hidden src="./assets/origen.png">
                            <span>{{state.CITY}}</span>
                            <!-- <small>Population: {{state.population}}</small> -->
                        </mat-option>
                    </mat-autocomplete>
                    <!-- <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let state of filteredStates | async" [value]="state.name">
                            <img class="example-option-img" aria-hidden [src]="state.flag" height="25">
                            <span>{{state.name}}</span> |
                            <small>Population: {{state.population}}</small>
                        </mat-option>
                    </mat-autocomplete> -->
                </div>
                <!-- END DESTINO -->

                <!-- OCULTAR CAMPO -->
                <div class="columnFiltro hideColumn">
                    <input name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [footerTemplate]="footerTemplate" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                    </ng-template>
                    <ng-template #footerTemplate>
                        <div class="btnDatePicker">
                            <hr class="my-0">
                            <button (click)="datepicker.close()">Listo</button>
                        </div>
                    </ng-template>
                </div>
                <!-- END OCULTAR CAMPO -->

                <!-- SOLO IDA -->
                <div class="columnFiltro onlyDate" *ngIf="selected === 'ida'">
                    <div class="iconCalendar">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                                stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <input class="form-control custom omac" (click)="d.toggle()" placeholder="Salida" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
                </div>
                <!-- END SOLO IDA -->

                <!-- IDA Y VUELTA -->
                <div class="columnFiltro" *ngIf="selected === 'idavuelta'">
                    <div class="iconCalendar">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                                stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <input #dpFromDate class="form-control custom inputDer" (click)="datepicker.toggle()" placeholder="Salida" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                </div>
                <div class="columnFiltro" *ngIf="selected === 'idavuelta'">
                    <div class="iconCalendar">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
                                stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <input #dpToDate class="form-control custom inputIzq" (click)="datepicker.toggle()" placeholder="Vuelta" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                </div>
                <!-- BOTOM -->
                <div class="columnFiltro flexCero">
                    <button (click)="send()" class="w-100">Buscar</button>
                </div>
                <!-- END BOTOM -->
                <!-- END IDA Y VUELTA -->
            </div>
        </div>


        <!-- END FILTROS -->
    </div>
    <ul id="cdr">
        <li>
            <div class="container">
                <div class="row">
                    <div class="col-6 d-flex align-items-center pr-0">
                        <svg class="d-inline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.32455 0.247574C9.31378 0.247574 10.2625 0.640545 10.962 1.34004C11.6615 2.03953 12.0545 2.98825 12.0545 3.97748C12.0545 4.96671 11.6615 5.91542 10.962 6.61492C10.2625 7.31441 9.31378 7.70738 8.32455 7.70738C7.33532 7.70738 6.38661 7.31441 5.68711 6.61492C4.98762 5.91542 4.59465 4.96671 4.59465 3.97748C4.59465 2.98825 4.98762 2.03953 5.68711 1.34004C6.38661 0.640545 7.33532 0.247574 8.32455 0.247574ZM8.32455 9.57233C12.4461 9.57233 15.7844 11.2415 15.7844 13.3022V15.1672H0.864746V13.3022C0.864746 11.2415 4.20301 9.57233 8.32455 9.57233Z"
                                fill="#DF0C30" />
                        </svg>
                        <div class="d-inline">
                            <h4>Adultos</h4>
                            <span>12 o más años</span>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <button class="count" name="adultos" (click)="count(-1, $event)">
                            -
                        </button>
                        <input type='text' id="adultos" value="{{ pasajeros[0].adultos }}">
                        <button class="count" name="adultos" (click)="count(+1, $event)">
                            +
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </li>
        <li>
            <div class="container">
                <div class="row">
                    <div class="col-6 d-flex align-items-center pr-0">
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M5.59493 11.2267C6.1743 11.2267 6.64397 10.7571 6.64397 10.1777C6.64397 9.59833 6.1743 9.12866 5.59493 9.12866C5.01557 9.12866 4.5459 9.59833 4.5459 10.1777C4.5459 10.7571 5.01557 11.2267 5.59493 11.2267Z"
                                    fill="#DF0C30" />
                                <path d="M15.5824 8.40343C15.6621 7.71526 15.7355 6.93128 15.7355 6.39208C15.7355 2.52673 11.2317 1.08606 8.39228 1.08606C6.55018 1.08606 5.2207 2.07425 4.77381 2.4582C3.3751 2.53862 1.04904 3.42891 1.04904 6.39208C1.04904 6.93898 1.12177 7.71946 1.2008 8.40413C0.492347 8.69366 0 9.37903 0 10.1777C0 11.1876 0.785378 12.0093 1.77567 12.0862C2.7233 14.8368 5.3284 16.8216 8.39228 16.8216C11.4562 16.8216 14.0613 14.8375 15.0089 12.0862C15.9999 12.0093 16.7846 11.1876 16.7846 10.1777C16.7846 9.37833 16.2922 8.69296 15.5824 8.40343ZM14.1095 11.4554C14.1095 11.4568 14.1081 11.4575 14.1081 11.4589V11.461C13.3906 13.9472 11.1023 15.7726 8.39228 15.7726C5.4375 15.7726 2.98555 13.6024 2.52398 10.7715L3.22194 8.33C3.84506 8.13208 5.24098 7.5719 5.85222 6.38928C6.79285 7.27817 8.90071 8.36916 13.5913 8.42721L14.2613 10.768C14.2235 11.0015 14.1739 11.2309 14.1095 11.4554Z"
                                    fill="#DF0C30" />
                                <path d="M11.1897 11.2267C11.769 11.2267 12.2387 10.7571 12.2387 10.1777C12.2387 9.59833 11.769 9.12866 11.1897 9.12866C10.6103 9.12866 10.1406 9.59833 10.1406 10.1777C10.1406 10.7571 10.6103 11.2267 11.1897 11.2267Z"
                                    fill="#DF0C30" />
                                <path d="M9.77634 12.7722C9.01334 13.51 7.77128 13.51 7.00829 12.7722C6.80058 12.5729 6.46768 12.5771 6.26627 12.7862C6.06485 12.9946 6.07115 13.3261 6.27956 13.5282C6.86212 14.0905 7.62722 14.3709 8.39231 14.3709C9.15741 14.3709 9.92251 14.0905 10.5051 13.5282C10.7135 13.3268 10.7191 12.9946 10.5184 12.7862C10.3169 12.5771 9.98475 12.5729 9.77634 12.7722Z"
                                    fill="#DF0C30" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="16.7846" height="16.7846" fill="white" transform="translate(0 0.736328)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <div class="d-inline">
                            <h4>Niños</h4>
                            <span>de 2 a 11 años</span>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <button class="count" name="ninos" (click)="count(-1, $event)">
                            -
                        </button>
                        <input type='text' id="ninos" formControlName="ninos" value="{{pasajeros[0].ninos}}">
                        <button class="count" name="ninos" (click)="count(+1, $event)">
                            +
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </li>
        <li>
            <div class="container">
                <div class="row">
                    <div class="col-6 d-flex align-items-center  pr-0">
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3381 9.74065C18.4004 9.53642 18.4536 9.32952 18.4975 9.12056C18.5003 9.1047 18.5013 9.08978 18.505 9.07393C18.5236 8.98255 18.532 8.88837 18.5469 8.79605C18.5703 8.64965 18.5982 8.50419 18.6122 8.35686C18.7232 7.2578 18.5881 6.14782 18.2166 5.10749C17.8452 4.06717 17.2467 3.12261 16.4647 2.34239C15.7726 1.64882 14.9504 1.09879 14.0451 0.723858C13.1399 0.348929 12.1695 0.156493 11.1897 0.157597V7.6174H4.21199L3.41193 5.75245H0V7.6174H2.18293L3.88749 11.5953C3.2899 11.8416 2.77877 12.2596 2.41875 12.7963C2.05873 13.3331 1.86601 13.9646 1.86495 14.611C1.86495 16.4107 3.32894 17.8746 5.12862 17.8746C6.76884 17.8746 8.11627 16.654 8.34473 15.0772H10.3048C10.5332 16.654 11.8807 17.8746 13.5209 17.8746C15.3206 17.8746 16.7846 16.4107 16.7846 14.611C16.7846 14.0235 16.6158 13.4799 16.3435 13.0025C16.3827 12.9642 16.4265 12.9307 16.4657 12.8915C16.8014 12.5549 17.1072 12.1837 17.3767 11.7874C17.6452 11.3865 17.8765 10.9613 18.063 10.5202C18.0779 10.4857 18.0872 10.4493 18.1012 10.4148H18.1022L18.1161 10.3757C18.1982 10.1705 18.2728 9.96445 18.3353 9.75277L18.3381 9.74065ZM5.12862 16.0097C4.35746 16.0097 3.7299 15.3821 3.7299 14.611C3.7299 13.8398 4.35746 13.2123 5.12862 13.2123C5.89978 13.2123 6.52733 13.8398 6.52733 14.611C6.52733 15.3821 5.89978 16.0097 5.12862 16.0097ZM13.5209 16.0097C12.7497 16.0097 12.1222 15.3821 12.1222 14.611C12.1222 13.8398 12.7497 13.2123 13.5209 13.2123C14.2921 13.2123 14.9196 13.8398 14.9196 14.611C14.9196 15.3821 14.2921 16.0097 13.5209 16.0097Z"
                                fill="#DF0C30" />
                        </svg>

                        <div class="d-inline">
                            <h4>Infantes</h4>
                            <span>menores de 2 años</span>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <button class="count" name="infantes" (click)="count(-1, $event)">
                            -
                        </button>
                        <input type='text' formControlName="infantes" id="infantes" value="{{pasajeros[0].infantes}}">
                        <button class="count" name="infantes" (click)="count(+1, $event)">
                            +
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </li>
        <hr>
        <li class="d-flex justify-content-between">
            <!-- <button mat-raised-button color="primary">Cancelar</button>
            <button mat-raised-button color="accent">Listo</button> -->

            <button class="cancel" (click)="customerClose($event)">Cancelar</button>
            <button>Listo</button>
        </li>
    </ul>
</form>