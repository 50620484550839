import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, forkJoin, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamsVuelos } from 'src/app/Component/home-page/resultados/models/resultados.interfaces';
import { IAereolineas } from 'src/app/shared/components/aereolineas/aereolineas.interfaces';
import { EnumCabinsVuelos, EnumFlightType } from 'src/app/shared/components/tabs/tabs.models';
import { toUp } from 'src/app/shared/utils';
import { FlightService } from '../flight/flight.service';
import { IDestinationDetails, IIataDetail, IPopularRoutes, ISeoFaq } from './destino.models';
import { DestinosService } from './services/destinos.service';

export interface IVueloDestino {
	IataCode: string;
	OriginCode: string;
	Origin: string;
	DestinationCode: string;
	Destination: string;
	Rate: number;
	Tax: number;
	Total: number;
	DateStart: string;
	DateEnd: string;
	SearchTime: string;
}

@Component({
	selector: 'app-destinos',
	templateUrl: './destinos.component.html',
	styleUrls: ['./destinos.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
			transition('* => void', [style({ opacity: 0 })])
		])
	]
})
export class DestinosComponent implements OnInit {
	public codigoCiudad: string;
	public origen: string;
	public destino: string;
	destinationContent: IIataDetail | null = null;
	seoFaq: ISeoFaq[];
	popularRoutes: IPopularRoutes[] = [];
	popularRoutesSection: IPopularRoutes[][] = [];
	loadingSeoData: boolean = false;

	public title: string;
	public subTitle: string;
	informationCard: IDestinationDetails[] = [];
	placeholderItems = Array(4)
		.fill(0)
		.map((x, i) => i);

	site: string = '';
	isFlight: boolean = false;

	limit: number = 2;

	vuelos: any;
	isLoading: boolean = true;

	$aereolineas: Observable<IAereolineas[]>;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private service: DestinosService,
		private flightService: FlightService,
		private _router: Router,
		private destinosService: DestinosService
	) {
		this.site = 'nmviajes';
		this.isFlight = false;
	}

	ngOnInit(): void {
		toUp();
		this._activatedRoute.params.subscribe((param) => {
			this.loadCiudad(param);
			this.loadDestinationDetails(param);
		});

		this.loadAereolineas();
		this.slider();
	}

	loadDestinationDetails(param: Params) {
		const cityCode = param.codigoCiudad || '';
		this.loadingSeoData = true;
		this.resetSeoData();
		this.destinosService.getDestinationByIata(cityCode).subscribe((destination) => {
			if (destination) {
				this.destinationContent = destination;

				forkJoin({
					details: this.destinosService.getDestinationDetails(destination.id),
					questions: this.destinosService.getDestinationQuestions(destination.id),
					routes: this.destinosService.getDestinationRoutes(destination.id)
				}).subscribe({
					next: ({ details, questions, routes }) => {
						if (details) {
							this.informationCard = details;
						}
						if (questions) {
							this.seoFaq = questions;
						}
						if (routes) {
							this.popularRoutes = routes;
							this.popularRoutesSection = this.popularRoutes.reduce((acc: IPopularRoutes[][], curr, i) => {
								const index = Math.floor(i / 3);
								acc[index] = acc[index] || [];
								acc[index].push(curr);
								return acc;
							}, []);
						}

						this.loadingSeoData = false;
					},
					error: (error) => {
						this.loadingSeoData = false;
					}
				});
			}
		});
	}

	resetSeoData() {
		this.destinationContent = null;
		this.informationCard = [];
		this.seoFaq = [];
		this.popularRoutes = [];
		this.popularRoutesSection = [];
	}

	loadCiudad(param: Params) {
		this.codigoCiudad = param.codigoCiudad || '';

		this.service
			.getVuelos(this.codigoCiudad)
			.pipe(map((c) => c.slice(0, 6)))
			.subscribe((data) => {
				this.isLoading = false;
				console.log(data);
				this.vuelos = data;

				this.origen = data[0].Origin;
				this.destino = data[0].Destination;

				this.title = `Vuelos desde ${this.origen} a ${this.destino}`;
				this.subTitle = `Mejores ofertas a ${this.destino} en los ultimos 15 minutos`;
			});
	}

	loadAereolineas() {
		this.$aereolineas = this.flightService.getAereolineas();
	}

	toLine(entity: IAereolineas) {
		this._router.navigateByUrl(`/aerolineas/${entity.IataCode}`);
	}

	generateParams(v: IVueloDestino) {
		return new ParamsVuelos(
			EnumFlightType.ida_vuelta.toString(),
			`${v.OriginCode} ${v.Origin}`,
			`${v.DestinationCode} ${v.Destination}`,
			v.DateStart,
			v.DateEnd,
			'1',
			'0',
			'0',
			EnumCabinsVuelos.economy
		);
	}

	buscarVuelo(vuelo: IVueloDestino) {
		const params = this.generateParams(vuelo);
		let url = '/resultados?rand=' + Math.round(Math.random() * 10000000000) + '&';
		url += `departureLocation=${params.departure}&arrivalLocation=${params.destination}&departureDate=${params.departureDate}&arrivalDate=${params.arrivalDate}&adults=${params.adults}&children=${params.children}&infants=${params.infants}&flightType=${params.flightType}&flightClass=${params.flightClass}&lang=ES&email=${params.email}`;
		localStorage.setItem('searchParams', url);
		this._router.navigateByUrl(url);
	}

	slider() {
		const contador = interval(4000);
		contador.subscribe((n) => {
			this.counter < 3 ? this.counter++ : (this.counter = 1);
			this.counterMovil < 8 ? this.counterMovil++ : (this.counterMovil = 1);
		});
	}

	id: any = 'option1';

	showOption(ids: any) {
		this.id = ids;
	}

	counter: number = 1;
	counterMovil: number = 1;

	nextBtn() {
		this.counter < 3 ? this.counter++ : (this.counter = 1);
	}

	afterBtn() {
		this.counter > 1 ? this.counter-- : (this.counter = 3);
	}

	viewMoreOffers(more: boolean): void {
		if (more) {
			this.limit = 2;
		} else {
			this.limit = this.vuelos.length;
		}
	}
}
