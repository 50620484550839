import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CoreModule } from '../../core';
import { FooterModule } from '../../shared/components/footer/footer.module';
import { PreFooterModule } from '../../shared/components/pre-footer/pre-footer.module';
import { ContainerComponent } from './container.component';
import { FooterCheckoutModule } from '../../shared/components/footer-checkout/footer-checkout.module';

@NgModule({
	declarations: [ ContainerComponent ],
	imports: [ CommonModule, RouterOutlet, PreFooterModule, FooterModule, CoreModule, FooterCheckoutModule ]
})
export class ContainerModule {
}
