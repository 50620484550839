<section class="container__boletin">
    <div class="container__boletin__section">
        <div class="container__boletin__section__info">
            <h4>Suscríbete a nuestro boletín</h4>
            <p> <span class="bold">Recibe ofertas y promociones exclusivas.</span> 
                 Obtén el acceso directo a los precios más bajos.</p>
            
        </div>
        <div class="container__boletin__section__button">
            <app-button [text]="'Suscribirme'" [isMobile]="true" [fullWidth]="true" (onClick)="openModalBulletin()"></app-button>
        </div>
    </div>
</section>