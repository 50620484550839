<div class="section__search">
	<section>
		<div class="section__search__multi" *ngFor="let item of arrayMulti" [class.more-row]="arrayMulti.length > 1">
			<app-input-search-flight [typeFlight]="2" [indexRowMulti]="item.index"></app-input-search-flight>
			<app-input-range
				(openDatepicker)="openDatepicker($event)"
				[idRowMulti]="item.index"
				[minDate]="item.minDate"
				[typeFlight]="2"></app-input-range>
			<img *ngIf="arrayMulti.length > 1" src="./assets/svg/deleteMulti.svg" alt="de" (click)="deleteRow(item.index)" />
		</div>
	</section>

	<div class="section__search__actions">
		<article>
			<app-button
				[text]="'Añadir destino'"
				[isMobile]="true"
				[fullWidth]="true"
				[disabled]="arrayMulti.length > 4"
				[color]="'outline-primary'"
				[left]="true"
				[icon]="'plusRed.svg'"
				(onClick)="addMulti()"></app-button>
		</article>
		<article>
			<app-button
				[text]="'Buscar'"
				[isMobile]="true"
				[fullWidth]="true"
				[left]="true"
				[icon]="'search.svg'"
				(onClick)="searchMulti()"></app-button>
		</article>
	</div>
</div>
