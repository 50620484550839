<div class="container__notify" [ngClass]="'container__notify__' + typeNotification" *ngIf="isShow">
	<div class="container__notify__contend">
		<ng-container *ngIf="typeNotification === types.ERROR || typeNotification === types.WARNING">
			<img src="./assets/svg/warning.svg" alt="!" />
		</ng-container>
		<div class="d-flex flex-column">
			<span
				><strong>{{ title }}</strong></span
			>
			<span>{{ message }}</span>
		</div>
	</div>
	<img (click)="isShow = false" src="./assets/svg/close-notification.svg" alt="" />
</div>
