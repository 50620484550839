<nmviajes-loading></nmviajes-loading>
<router-outlet></router-outlet>
<app-notification></app-notification>
<a
	id="btn-whatsapp"
	class="position-fixed text-end rounded-start"
	href="https://api.whatsapp.com/send?phone={{ whatsAppNumber }}"
	target="_blank"
	rel="noreferrer noopenner"
	role="button"
	ngbTooltip="Contáctanos"
	tooltipClass="me-2"
	placement="start"
	[openDelay]="250"
	[closeDelay]="2000">
	<img src="./assets/whatsapp-icon.svg" alt="Whatsapp chat" class="img-fluid" />
</a>
