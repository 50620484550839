<article class="card shadow h-100">
	<picture class="image-container">
		<img [src]="urlImage"
		     loading="lazy"
		     alt="{{ title }}"
		     class="card-img-top img-fluid"
		     onerror="this.src='./assets/home/bgdefault.png'" />
	</picture>
	<section class="card-body card-content">
		<div class="d-flex justify-content-between align-items-center flex-wrap">
			<p class="small card-text text-nowrap fw-bold mb-1">BOLETO AÉREO</p>
			<p class="small card-text text-nowrap fw-bold text-primary mb-1">IDA Y VUELTA</p>
		</div>
		<header class="mb-auto">
			<h3 class="card-title inter-extra-bold text-break mb-0">{{ title }}</h3>
			<p class="small card-subtitle text-secondary fw-medium mb-2">{{ description | uppercase }}</p>
		</header>
		<span class="xx-small card-text text-secondary fw-medium mb-1">PRECIO DESDE:</span>
		<p class="fs-3 card-text inter-extra-bold text-primary mb-1 mb-xl-2">
			{{ price | currency: 'USD' : 'US$' : '1.0-2' }}
			<ng-container *ngIf="exchange">
				<span class="vr text-secondary align-middle mx-1"></span>
				<span class="text-nowrap">
					{{ getExchangeAmount(exchange, price) | currency:'PEN':'S/ ':'1.0-0' }}
				</span>
			</ng-container>
		</p>
		<app-filled-button [btnBlock]="true"
		                   (btnClick)="viewRates(destinationCode)"
		                   label="¡Lo quiero!"></app-filled-button>
	</section>
</article>
