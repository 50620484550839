<div class="card shadow h-100">
	<div class="image-container">
		<img [src]="item.ImageUrl"
		     [alt]="item.LargeTitle"
		     loading="lazy"
		     class="card-img-top img-fluid"
		     onerror="this.src='./assets/home/bgdefault.png'" />
	</div>
	<div class="card-body d-flex flex-column flex-grow-1 pt-xl-4 px-xl-4">
		<p class="small card-text fw-bold mb-0">PAQUETE</p>
		<h4 class="card-title inter-bold">{{ item.Title }}</h4>
		<p class="card-subtitle badge rounded-3 text-bg-orange fs-6 fw-bold py-2 mt-auto me-auto mb-2">
			{{ item.Counters.HotelNights + 1 }} D&Iacute;AS | {{ item.Counters.HotelNights }} NOCHES
		</p>
		<p class="small card-text text-uppercase text-secondary fw-medium">DESDE {{ item.Origin.Location.Name }}</p>
	</div>
	<hr class="my-0 mb-lg-1">
	<div class="card-body d-flex flex-column flex-grow-0 px-xl-4">
		<p class="xx-small card-text text-secondary fw-medium mb-1">
			PRECIO DESDE:
		</p>
		<p class="fs-3 card-text inter-extra-bold text-primary mb-1 mb-xl-2">
			{{ item.TotalPrice.Amount | currency:'USD':'US$ ':'1.0-0' }}
			<ng-container *ngIf="item.TotalPrice.CurrencyExchange">
				<span class="vr text-secondary align-middle mx-1"></span>
				<span class="text-nowrap">
					{{ getExchangeAmount(item.TotalPrice.CurrencyExchange, item.TotalPrice.Amount) | currency:'PEN':'S/ ':'1.0-0' }}
				</span>
			</ng-container>
		</p>
		<p class="fw-medium x-small mb-2 mb-xl-3">INCLUYE IMPUESTOS, TASAS Y CARGOS</p>
		<app-filled-button [hrefUrl]="item.ideaUrl"
		                   [btnBlock]="true"
		                   label="¡Lo quiero!"
		                   target="_blank"></app-filled-button>
	</div>
</div>
