<ng-container *ngIf="typeValue !== EnumFlightType.ida">
  <div class="colBoxtab w-15">
    <div class="hideDate">
      <!-- OCULTAR CAMPO -->
      <input name="datepicker2" style="border: 0.5px;" mask="d0/M0/0*" maxlength="11" placement="bottom right"
        [minDate]="minDate" ngbDatepicker #datepicker2="ngbDatepicker" [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
        [startDate]="fromDate!" tabindex="-1">
      <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null">
          {{ date.day }}</span>
      </ng-template>
      <!-- <ng-template #footerTemplate2>
        <div class="btnDatePicker">
          <hr class="my-0">
          <button (click)="datepicker2.close()">Listo</button>
        </div>
      </ng-template> -->
      <!-- END OCULTAR CAMPO -->
    </div>
    <div class="iconCalendar">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
          stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <input #dpFromDate2 mask="d0/M0/0*" [ngClass]="{'isvalid': requiredToDate && ( formatter.format(fromDate) === '')}"
      placement="bottom right" maxlength="11" class="form-control custom d-left" (click)="datepicker2.toggle()"
      (blur)="emitValue()" placeholder="Salida" name="dpFromDate2" [value]="formatter.format(fromDate)"
      (input)="fromDate = validateInput(fromDate, dpFromDate2.value)" autocomplete="off">
    <!-- <input class="form-control custom" type="text" placeholder="Salida"> -->
  </div>
  <div class="colBoxtab w-15">
    <div class="iconCalendar">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
          stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <input #dpToDate2 mask="d0/M0/0*" [ngClass]="{'isvalid': requiredToDate && ( formatter.format(toDate) === '')}"
      placement="bottom right" maxlength="11" class="form-control custom d-right" (click)="datepicker2.toggle()"
      (blur)="emitValue()" placeholder="Vuelta" name="dpToDate2" [value]="formatter.format(toDate)"
      (input)="toDate = validateInput(toDate, dpToDate2.value)" autocomplete="off">
    <!-- <input class="form-control custom" type="text" placeholder="Vuelta"> -->
  </div>
</ng-container>

<ng-container *ngIf="typeValue === EnumFlightType.ida">
  <div class="colBoxtab w-15 oneWayCalendar">

    <div class="hideDate">
      <!-- OCULTAR CAMPO -->
      <input name="datepicker3" mask="d0/M0/0*" placement="bottom right" maxlength="11" [minDate]="minDate"
        ngbDatepicker #datepicker3="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelectionIda($event)"
        outsideDays="hidden" [startDate]="fromDate!" tabindex="-1" [displayMonths]="1">
      <!-- <ng-template #footerTemplate3>
        <div class="btnDatePicker">
          <hr class="my-0">
          <button (click)="datepicker3.close()">Listo</button>
        </div>
      </ng-template> -->
      <!-- END OCULTAR CAMPO -->
    </div>


    <div class="iconCalendar ">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6365 3.09082H5.18195C3.07323 3.09082 1.36377 4.80028 1.36377 6.909V16.4545C1.36377 18.5632 3.07323 20.2726 5.18195 20.2726H16.6365C18.7452 20.2726 20.4547 18.5632 20.4547 16.4545V6.909C20.4547 4.80028 18.7452 3.09082 16.6365 3.09082Z"
          stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.09082 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.7275 1.18164V4.99982" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.36377 8.81787H20.4547" stroke="#B9BCBF" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <input id="txtInitDate" [ngClass]="{'isvalid': requiredFromDate && ( formatter.format(fromDate) === '')}"
      #dpFromDate3 mask="d0/M0/0*" placement="bottom right" maxlength="11" class="form-control custom d-left isvalid"
      (click)="datepicker3.toggle()" (blur)="emitValue()" [placeholder]="placeHolder" name="dpFromDate3"
      [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate3.value)"
      autocomplete="off">


  </div>

</ng-container>