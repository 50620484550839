import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Offer } from 'src/app/Models/offers/offers.model';
import { OffersService } from 'src/app/Services/offers/offers.service';
import { finalize, take } from 'rxjs/operators';
import { ConfigCatService } from '../../../../../../shared/services/config-cat.service';
import { TokenService } from '../../../../../../api/api-nmviajes/services/token.service';
import { ExchangeRate } from '../../../../../../api/api-checkout/models/rq-checkout-search';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-section-super-offers',
	templateUrl: './section-super-offers.component.html',
	styleUrls: [ './section-super-offers.component.scss' ]
})
export class SectionSuperOffersComponent implements OnInit, OnDestroy {
	protected _isBrowser: Boolean = false;
	private _currentPagNac = 0;
	private _currentPagInc = 0;
	private _totalLimit = 8;
	private _flagSubscription = new Subscription();

	private _exchangeRate: ExchangeRate | null = null;
	private _exchangeSubscription = new Subscription();

	isLoading = false;
	placeholderItems = Array(4).fill(0).map((x, i) => i);

	indexTab = 1;

	internationalFlights: Offer[] = [];
	nationalFlights: Offer[] = [];

	internationalFlightsPag: Offer[] = [];
	nationalFlightsPag: Offer[] = [];

	showOffersNac = true;
	showOffersInt = true;

	constructor(
			private offersService: OffersService,
			private _router: Router,
			private _configCatService: ConfigCatService,
			private _tokenService: TokenService,
			@Inject(PLATFORM_ID) private platformId: Object
	) {
		if (isPlatformBrowser(this.platformId)) this._isBrowser = true;
	}

	ngOnInit(): void {
		this.getExchangeRate();
		this.listLimitSubscription();
	}

	private listLimitSubscription() {
		this._flagSubscription = this._configCatService.cardListLimitFlag$.subscribe(result => {
			this._totalLimit = result ? 12 : 8;
			this.offersNacInt();
		});
	}

	private getExchangeRate() {
		this._exchangeSubscription = this._tokenService.exchangeRate$.subscribe(result => {
			this._exchangeRate = result;
			this.nationalFlightsPag.forEach(item => item.CurrencyExchange = result);
			this.internationalFlightsPag.forEach(item => item.CurrencyExchange = result);
		});
	}

	private offersNacInt() {
		this.isLoading = true;
		this.offersService.getOffersNationalInternational().pipe(
				take(1),
				finalize(() => {
					if (this._isBrowser) setTimeout(() => this.isLoading = false, 800);
				})
		).subscribe({
			next: (res) => {
				this.nationalFlights = res.Result.filter((item) => item.Type == 'NAC')
						.map(item => this.addExchangeRateToOffer(item)).slice(0, this._totalLimit);
				this.internationalFlights = res.Result.filter((item) => item.Type == 'INT')
						.map(item => this.addExchangeRateToOffer(item)).slice(0, this._totalLimit);
				if (this.nationalFlights.length == 0) this.showOffersNac = false;
				if (this.internationalFlights.length == 0) this.showOffersInt = false;
				this.updateDisplayedDataNac();
				this.updateDisplayedDataInc();
			},
			error: () => {
				this.showOffersNac = false;
				this.showOffersInt = false;
			}
		});
	}

	showMore() {
		if (this.indexTab == 1) this.updateDisplayedDataNac();
		if (this.indexTab == 2) this.updateDisplayedDataInc();
	}

	private updateDisplayedDataNac() {
		const currentPagNac = this._currentPagNac + 4;
		if (currentPagNac > this.nationalFlights.length) {
			this.nationalFlightsPag = this.nationalFlights.map(item => this.addExchangeRateToOffer(item)).slice(0, 4);
			this._currentPagNac = 4;
			this.scrollReset();
		} else {
			this.nationalFlightsPag = this.nationalFlights.map(item => this.addExchangeRateToOffer(item))
					.slice(0, currentPagNac);
			this._currentPagNac = currentPagNac;
		}
	}

	private updateDisplayedDataInc() {
		const currentPagInc = this._currentPagInc + 4;
		if (currentPagInc > this.internationalFlights.length) {
			this.internationalFlightsPag = this.internationalFlights.map(item => this.addExchangeRateToOffer(item)).slice(0, 4);
			this._currentPagInc = 4;
			this.scrollReset();
		} else {
			this.internationalFlightsPag = this.internationalFlights.map(item => this.addExchangeRateToOffer(item))
					.slice(0, currentPagInc);
			this._currentPagInc = currentPagInc;
		}
	}

	private addExchangeRateToOffer(item: Offer) {
		return {
			...item,
			CurrencyExchange: this._exchangeRate
		} as Offer;
	}

	private scrollReset() {
		if (this._isBrowser)
			document.querySelector('#sectionOffers')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	trackByUserId(index: number, name: any ): any  { 
		return name.DestinationCode ;   
	}  
	trackByNameId(index: number, name: any ): any  { 
		return name.DestinationCode ;   
	} 

	ngOnDestroy() {
		this._flagSubscription.unsubscribe();
	}
}
