<section class="bg-white pt-3 pt-md-4 pt-lg-5"
         *ngIf="nationalFlights.length > 0 || internationalFlights.length > 0 || isLoading">
	<div class="container">
		<div class="d-flex flex-column row-gap-3 row-gap-lg-4" id="sectionOffers">
			<h2 class="fs-2 fw-bolder text-start mb-0">S&uacute;per ofertas de vuelos</h2>
			<ul ngbNav #nav="ngbNav" [(activeId)]="indexTab" class="nav nav-underline px-3 z-1">
				<li [ngbNavItem]="1">
					<a ngbNavLink class="py-2">Vuelos Nacionales</a>
					<ng-template ngbNavContent>
						<section class="px-2 px-md-0 row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 g-md-4 g-xl-5">
							<ng-container *ngIf="!isLoading; else loading">
								<app-newcard-offer
									[ngClass]="'col'"
									[urlImage]="national.Image"
									[title]="national.Destination | titlecase"
									[description]="'Desde Lima'"
									[destinationCode]="national.DestinationCode"
									[price]="national.Rate"
									[exchange]="national.CurrencyExchange?.amount ?? 0"
									*ngFor="let national of nationalFlightsPag; trackBy: trackByUserId"></app-newcard-offer>
							</ng-container>
						</section>
						<ng-container *ngIf="isLoading || nationalFlights.length > 4">
							<div class="text-center mt-3 mt-lg-4  mb-4">
								<app-outline-button 
									[label]="nationalFlightsPag.length >= nationalFlights.length ? 'Ver menos' : 'Ver más ofertas'"
									[disabled]="isLoading"
									(btnClick)="showMore()"></app-outline-button>
							</div>
						</ng-container>
					</ng-template>
				</li>
				<li [ngbNavItem]="2">
					<a ngbNavLink class="py-2">Vuelos Internacionales</a>
					<ng-template ngbNavContent>
						<section class="px-2 px-md-0 row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 g-md-4 g-xl-5">
							<ng-container *ngIf="!isLoading; else loading">
								<app-newcard-offer
									[ngClass]="'col'"
									[urlImage]="international.Image"
									[title]="international.Destination | titlecase"
									[description]="'Desde Lima'"
									[destinationCode]="international.DestinationCode"
									[price]="international.Rate"
									[exchange]="international.CurrencyExchange?.amount ?? 0"
									*ngFor="let international of internationalFlightsPag;  trackBy: trackByNameId"></app-newcard-offer>
							</ng-container>
						</section>
						<ng-container *ngIf="isLoading || internationalFlights.length > 4">
							<div class="text-center mt-3 mt-lg-4 mb-4">
								<app-outline-button
									[label]="nationalFlightsPag.length >= nationalFlights.length ? 'Ver menos' : 'Ver más ofertas'"
									[disabled]="isLoading"
									(btnClick)="showMore()"></app-outline-button>
							</div>
						</ng-container>
					</ng-template>
				</li>
			</ul>
			<div [ngbNavOutlet]="nav"></div>
			<ng-template #loading>
				<app-card-placeholder imgTopStyle="md"
				                      [ngClass]="'col'"
				                      *ngFor="let _ of placeholderItems"></app-card-placeholder>
			</ng-template>
		</div>
	</div>
</section>