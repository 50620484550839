<div class="alert-card" [ngClass]="{ confirmed: currentStep === 'confirmed' }">
	<ng-container [ngSwitch]="currentStep">
		<ng-container *ngSwitchCase="'initial'">
			<div class="card-content">
				<span><img src="assets/vuelos/destino/email-icon.svg" alt="" /></span>
				<span class="message">¿QUIERES RECIBIR ALERTAS SOBRE VUELOS A {{ city }}?</span>
				<app-filled-button
					[btnClass]="'btn-class-sub'"
					[hierarchy]="'primary'"
					[size]="'sm'"
					[label]="'SI QUIERO'"
					(btnClick)="proceedToEmail()"></app-filled-button>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="'email'">
			<form [formGroup]="emailForm" (ngSubmit)="submitEmail()">
				<label class="mb-0 pb-0" for="email">INGRESA TU CORREO ELECTRÓNICO</label>
				<input id="email" type="email" formControlName="email" placeholder="micorreo@mail.com" />
				<app-filled-button
					[disabled]="loading"
					[btnClass]="'btn-class-sub'"
					[hierarchy]="'primary'"
					[size]="'sm'"
					[label]="'ENVIAR'"
					[disabled]="!emailForm.valid"
					(btnClick)="submitEmail()"></app-filled-button>
			</form>
		</ng-container>

		<ng-container *ngSwitchCase="'confirmed'">
			<div class="card-content">
				<span class="message">¡EXCELENTE! TE NOTIFICAREMOS CUANDO EXISTAN LAS MEJORES OFERTAS A {{ city }}</span>
				<span><img src="assets/vuelos/destino/check-icon.svg" alt="" /></span>
			</div>
		</ng-container>
	</ng-container>
</div>
