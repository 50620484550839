import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { UserStorage } from '../../Services/accounts.service';
import * as configcat from 'configcat-js-ssr';

@Injectable({
	providedIn: 'root'
})
export class ConfigCatService {
	private _configCat: configcat.IConfigCatClient;
	private user: UserStorage | undefined;
	protected _isBrowser = false;

	private _cardListLimitFlag = new Subject<boolean>();

	bookingTestingFlag = false;

	get cardListLimitFlag$() {
		return this._cardListLimitFlag.asObservable();
	}

	setCardListLimitFlag(value: boolean) {
		this._cardListLimitFlag.next(value);
	}

	getBookingUrl() {
		return this.bookingTestingFlag ? '/booking/v2' : '/booking';
	}

	constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
		if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
	}

	initClient() {
		const logger = configcat.createConsoleLogger(configcat.LogLevel.Info);
		this._configCat = configcat.getClient(environment.configCatSdkKey, configcat.PollingMode.AutoPoll, {
			logger,
			setupHooks: (hooks) =>
					hooks.on('flagEvaluated', evaluationDetails => {
						const variant = `configcat-${evaluationDetails.key}-${evaluationDetails.value}`;
						if (this._isBrowser)
							(window as any).dataLayer.push({
								event: 'abTestVariation',
								variation: variant,
								variation_id: evaluationDetails.variationId
							});
					})
		});
	}

	async getCardListLimitFlag() {
		return await this._configCat.getValueAsync('listadodecardshome', false);
	}

	async getBookingTestingFlag() {
		return await this.getFlagByUser('pasarelatesting');
	}

	async getWhatsAppAITestingFlag() {
		return await this.getFlagByUser('whatsappIaTesting');
	}

	private async getFlagByUser(key: string, defaultValue = false) {
		const user = this.getUserObject();
		return await this._configCat.getValueAsync(key, defaultValue, user);
	}

	private getUserObject(): configcat.User {
		if (this._isBrowser && !this.user) {
			const storedUser = localStorage.getItem('usuario');
			this.user = storedUser ? JSON.parse(storedUser) as UserStorage : undefined;
		}

		const random = (Math.random() + 1).toString(36).substring(7);
		return new configcat.User(this.user ? this.user.id.toString(10) : random, this.user?.email, 'PE');
	}
}
