import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingModule } from './components/loading/loading.module';
import { ContainerModule } from './features/container';
import { CanActivateCheckoutGuard } from './Guards/checkout.guard';
import { DeactivatePaymentGuard } from './Guards/payment.guard';
import { GlobalHttpInterceptorService } from './interceptors/globalHttpInterceptorService.service';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { MotorVuelosInterceptor } from './interceptors/motor-vuelos.interceptor';
import { PlatformInterceptor } from './interceptors/platform.interceptor';
import { CryptoService } from './Services/util/crypto.service';
import { NotificationModule } from './shared/components/notification/notification.module';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

if (!environment.production) (<any>window).FIREBASE_APPCHECK_DEBUG_TOKEN = true;

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [AppComponent, PageNotFoundComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		BrowserAnimationsModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideFirestore(() => getFirestore()),
		provideAppCheck(() =>
				initializeAppCheck(getApp(), {
					provider: new ReCaptchaV3Provider(environment.reCaptchaPublicKey),
					isTokenAutoRefreshEnabled: true
				})
		),
		ContainerModule,
		NotificationModule,
		LoadingModule,
		HttpClientModule,
		NgbTooltipModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: PlatformInterceptor, multi: true },
		CryptoService,
		CanActivateCheckoutGuard,
		DeactivatePaymentGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MotorVuelosInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
