<div class="popular-routes">
	<h4 class="fw-bold">Rutas populares hacia {{ destination | titlecase }}</h4>
	<ul>
		<ng-container *ngFor="let route of popularRoutes">
			<li (click)="onRedirect(route.ciudadOrigenUrl)" *ngIf="route.ciudadOrigenNombre">
				Vuelos a {{ destination | titlecase }} desde {{ route.ciudadOrigenNombre }}
			</li>
		</ng-container>
	</ul>
</div>
